import styled from "@emotion/styled"

export const Content = styled.div`
  margin: 20px 0 0 0;
  text-align: center;

  a {
    color: #5858ff;
  }
`
